<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons.min'
UIkit.use(Icons)
window.UIkit = UIkit

export default {
  name: 'App'
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

@import "../node_modules/uikit/dist/css/uikit.css";
</style>
