import { createWebHistory, createRouter } from 'vue-router'
import LoginComponent from '@/components/LoginComponent'
import SenderComponent from '@/components/SenderComponent'

const routes = [
  { path: '/', component: LoginComponent },
  { path: '/sender', component: SenderComponent }
]

const router = createRouter({
  history: createWebHistory(),
  // base: process.env.BASE_URL,
  scrollBehavior: () => {
    return { x: 0, y: 0 }
  },
  routes
})

export default router
