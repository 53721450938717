<template>
  <div class="uk-container">.
    <div uk-grid>
      <div class="uk-width-1-1@xl uk-width-1-1@l uk-width-1-1@m uk-width-1-1@s">
        <h1 class="uk-heading-small uk-align-center uk-flex-center">Send match report</h1>
      </div>
      <div class="uk-width-2-3@xl uk-width-2-3@l uk-width-1-1@m uk-width-1-1@s">
        <h2>Select parameters</h2>
          <form @submit.prevent="submitForm" class="uk-form-stacked">
            <div class="uk-margin">
              <label class="uk-form-label" for="mode">Mode</label>
              <div class="uk-form-controls">
                <select v-model="selectedMode" id="mode" name="mode" class="uk-select">
                  <option value="full">Full</option>
                  <option value="shortshifts">ShortShifts</option>
                </select>
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="mode">Report type</label>
              <div class="uk-form-controls">
                <select v-model="selectedType" id="type" name="type" class="uk-select">
                  <option value="_match2">Match report</option>
                  <option value="player_report2">Players report</option>
                  <option value="comboreport">Match report + Players report</option>
                </select>
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="filename" v-if="selectedType !== 'comboreport'">Filename</label>
              <div class="uk-form-controls">
                <input
                  v-model="fileName"
                  type="text"
                  id="filename"
                  name="filename"
                  class="uk-input"
                  placeholder="Enter filename"
                  v-if="selectedType !== 'comboreport'"
                />
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="filenameMatch" v-if="selectedType === 'comboreport'">Filename for Match Report</label>
              <div class="uk-form-controls">
                <input
                  v-model="matchReportFileName"
                  type="text"
                  id="filenameMatch"
                  name="matchReportFileName"
                  class="uk-input"
                  placeholder="Enter filename for Match report"
                  v-if="selectedType === 'comboreport'"
                />
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="filenamePlayers" v-if="selectedType === 'comboreport'">Filename for Players Report</label>
              <div class="uk-form-controls">
                <input
                  v-model="playersReportFileName"
                  type="text"
                  id="filenamePlayers"
                  name="playersReportFileName"
                  class="uk-input"
                  placeholder="Enter filename for Players report"
                  v-if="selectedType === 'comboreport'"
                />
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="matchId">Match ID</label>
              <div class="uk-form-controls">
                <input
                  v-model.number="matchId"
                  type="number"
                  id="matchId"
                  name="matchId"
                  class="uk-input"
                  placeholder="Enter match ID"
                />
                <p class="uk-text-danger" v-if="matchIdError">{{ matchIdError }}</p>
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="emails">Emails</label>
              <div class="uk-form-controls">
                <input
                  v-model="newEmail"
                  @keydown.enter.prevent="addEmail"
                  @keydown.space.prevent="addEmail"
                  @paste="handlePaste"
                  type="text"
                  id="emails"
                  name="emails"
                  class="uk-input"
                  placeholder="Enter an email"
                />
                <div class="uk-margin">
                  <button @click="openEmailListModal" class="uk-button uk-button-secondary">Load List of E-mails</button>
                </div>
                <p class="uk-text-danger" v-if="emailError">{{ emailError }}</p>
              </div>
              <!-- UIkit modal for loading email list -->
              <div id="emailListModal" uk-modal>
                <div class="uk-modal-dialog uk-modal-body">
                  <h2 class="uk-modal-title">Paste Email List</h2>
                  <textarea v-model="emailListText" class="uk-textarea" rows="5" placeholder="Paste email list here"></textarea>
                  <p class="uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">Cancel</button>
                    <button @click="addEmailList" class="uk-button uk-button-primary">OK</button>
                  </p>
                </div>
              </div>
            </div>
            <div class="uk-margin" v-if="emailList.length > 0">
              <div class="email-tags">
                <span
                  v-for="(email, index) in emailList"
                  :key="index"
                  class="email-tag"
                >
                  {{ email }}
                  <span class="remove-tag" @click="removeEmail(index)">
                    <i class="uk-icon" uk-icon="close"></i>
                  </span>
                </span>
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="language">Language</label>
              <div class="uk-form-controls">
                <select v-model="selectedLanguage" id="language" name="language" class="uk-select" @change="changeLanguage">
                  <option value="ru">Russian</option>
                  <option value="en">English</option>
                </select>
              </div>
            </div>
            <div class="uk-margin">
              <button @click="preview" class="uk-button uk-button-secondary uk-margin-right">Preview</button>
              <button @click="send" class="uk-button uk-button-primary">Send</button>
            </div>
          </form>
        </div>
      <div class="uk-width-1-3@xl uk-width-1-3@l uk-width-1-1@m uk-width-1-1@s" id="rightDiv">
        <div class="uk-margin">
          <div>
            <div class="uk-flex uk-flex-between">
              <div class="uk-flex uk-text-large uk-flex-inline uk-flex-middle">Status</div>
              <div :class="getStatusStyle" class="uk-flex status-indicator uk-flex-inline uk-flex-middle uk-text-small">{{ wsStatusText }}</div>
            </div>
            <ul class="uk-list">
              <li v-for="(message, index) in recentMessages" :key="index">
                <div class="message-date">{{ message.date }}</div>
                <div class="message-text">{{ message.text }}</div>
              </li>
            </ul>
          </div>
        </div>
        <div class="uk-margin">
          {{ this.wsMessage }}
        </div>
      </div>
    </div>
    <!-- UIkit modal for report details popup -->
    <div id="reportDetailsModal" uk-modal>
      <div class="uk-modal-dialog uk-modal-body">
        <h2 class="uk-modal-title">Report Details</h2>
        <p>Emails to Send:</p>
        <ul>
          <!-- Loop through the emails and display them -->
          <li v-for="(email, index) in resultJSON.emails" :key="index">{{ email }}</li>
        </ul>

        <p>Reports to Send:</p>
        <ul>
          <!-- Loop through the reports and display their details -->
          <li v-for="(report, index) in resultJSON.reports" :key="index">
            <p>
              Report Type: {{ report.type }}<br>
              Report URL: {{ report.url }}<br>
              Report Filename: {{ report.filename }}
            </p>
          </li>
        </ul>

        <p>Match ID: {{ resultJSON.match_id }}</p>

        <div class="uk-modal-footer uk-text-right">
          <button class="uk-button uk-button-default uk-modal-close" type="button">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import UIkit from 'uikit'

export default {
  name: 'SenderComponent',
  data () {
    return {
      emails: '',
      emailListText: '',
      newEmail: '',
      uniqueEmailsList: [],
      matchId: null,
      fileName: '',
      selectedLanguage: 'en',
      selectedMode: 'full',
      selectedType: '_match2',
      playersReportFileName: '',
      matchReportFileName: '',
      resultJSON: '',
      websocket: null,
      connected: false,
      messages: [],
      reportObjects: [],
      reconnectInterval: 5000
    }
  },
  mounted () {
    // Establish WebSocket connection
    this.connectWebSocket() // Initial connection
  },
  created () {
    // When the component is created, set the selected language from the "lang" cookie if it exists
    const langCookie = this.getCookie('lang')
    if (langCookie) {
      this.selectedLanguage = langCookie
    } else {
      // If the "lang" cookie doesn't exist, create it with the default language value
      this.createCookie('lang', this.selectedLanguage)
    }
  },
  computed: {
    reportType () {
      // Check the type parameter in the URL and return the corresponding report type
      if (this.reportObjects.length > 0) {
        const type = this.reportObjects[0].url.split('type=')[1]
        if (type === '_match2') {
          return 'Match report'
        } else if (type === 'player_report2') {
          return 'Players report'
        }
      }
      return '' // Default value if reportObjects is empty or type is not recognized
    },
    emailError () {
      if (!this.emails) {
        return 'Emails are required'
      }

      const emailArray = this.emails.split(',')
      for (const email of emailArray) {
        if (!isValidEmail(email.trim())) {
          return 'Invalid email format'
        }
      }
      return ''
    },
    matchIdError () {
      if (!this.matchId) {
        return 'Match ID is required'
      }
      return ''
    },
    emailList () {
      return this.emails.split(', ').filter(email => email.trim() !== '')
    },
    recentMessages () {
      // Limit the displayed messages to the last 20 by date
      return this.messages
        .slice(-20)
        .reverse()
        .map((message) => {
          const parts = message.split(': ')
          if (parts.length >= 2) {
            const date = parts[0]
            const text = parts.slice(1).join(': ') // Join the remaining parts with ": "
            return { date, text }
          } else {
            // Handle messages that don't have the expected format
            return { date: '', text: message }
          }
        })
    },
    wsStatus () {
      if (this.connected) {
        return 'connected' // WebSocket is connected
      } else if (this.connecting) {
        return 'reconnecting' // WebSocket is attempting to reconnect
      } else {
        return 'disconnected' // WebSocket is disconnected
      }
    },
    wsStatusText () {
      switch (this.wsStatus) {
        case 'connected':
          return 'Connected'
        case 'reconnecting':
          return 'Reconnecting...'
        default:
          return 'Disconnected'
      }
    },
    getStatusStyle () {
      switch (this.wsStatus) {
        case 'connected':
          return 'status-connected'
        case 'reconnecting':
          return 'status-reconnecting'
        default:
          return 'status-disconnected'
      }
    }
  },
  methods: {
    async preview () {
      if (this.emailError || this.matchIdError) {
        // Handle validation errors
        return
      }

      try {
        // Step 1: Fetch the JSON configuration from the URL
        const response = await axios.get('https://storage.yandexcloud.net/reports-utility-assets/config/config.json')
        const config = response.data

        console.log(config)

        // Find the selected report configuration based on the selectedType
        let selectedReportConfig

        if (this.selectedType === 'comboreport') {
          selectedReportConfig = { type: 'comboreport', name: 'Combo Report' }
        } else {
          selectedReportConfig = config.reports.find((report) => report.type === this.selectedType)

          if (!selectedReportConfig) {
            UIkit.notification('No matching report configuration found for the selected type.', {
              status: 'warning'
            })
            return
          }
        }

        // Construct the result JSON to send
        const resultJSON = {
          reports: [],
          match_id: this.matchId,
          emails: this.emailList.map((str) => str.trim())
        }

        if (this.selectedType === 'comboreport') {
          // Add two report objects for Match Report and Players Report
          resultJSON.reports.push({
            url: `${config.base_url}?API_TYPE=hockey&type=_match2&match_id=${this.matchId}&mode=${this.selectedMode}&lang_id=${this.selectedLanguage === 'ru' ? 0 : 1}`,
            filename: this.matchReportFileName.trim() + '.pdf',
            type: 'Match report'
          })
          resultJSON.reports.push({
            url: `${config.base_url}?API_TYPE=hockey&type=player_report2&match_id=${this.matchId}&mode=${this.selectedMode}&lang_id=${this.selectedLanguage === 'ru' ? 0 : 1}`,
            filename: this.playersReportFileName.trim() + '.pdf',
            type: 'Players report'
          })
        } else {
          // Add a single report object based on the selected type
          resultJSON.reports.push({
            url: `${config.base_url}?API_TYPE=hockey&type=${this.selectedType}&match_id=${this.matchId}&mode=${this.selectedMode}&lang_id=${this.selectedLanguage === 'ru' ? 0 : 1}`,
            filename: this.fileName.trim() + '.pdf',
            type: this.selectedType === '_match2' ? 'Match report' : 'Players report'
          })
        }

        // Open the UIkit modal
        UIkit.modal('#reportDetailsModal').show()

        // Store the result JSON in a component data property
        this.resultJSON = resultJSON
      } catch (error) {
        console.error('Error fetching or processing data:', error)
        UIkit.notification('An error occurred while preparing links.', { status: 'danger' })
      }
    },
    async send () {
      if (this.emailError || this.matchIdError) {
        return // Handle validation errors
      }
      try {
        const configResponse = await axios.get(
          'https://storage.yandexcloud.net/reports-utility-assets/config/config.json'
        )
        const config = configResponse.data

        let selectedReportConfig

        if (this.selectedType === 'comboreport') {
          selectedReportConfig = { type: 'comboreport', name: 'Combo Report' }
        } else {
          selectedReportConfig = config.reports.find(
            (report) => report.type === this.selectedType
          )

          if (!selectedReportConfig) {
            UIkit.notification('No matching report configuration found for the selected type.', {
              status: 'warning'
            })
            return
          }
        }

        const trimmedArray = this.emailList.map((str) => str.trim())

        const requestBody = {
          reports: [],
          match_id: this.matchId,
          emails: trimmedArray
        }

        if (this.selectedType === 'comboreport') {
          // Add two report objects for Match Report and Players Report
          requestBody.reports.push({
            url: `${config.base_url}?API_TYPE=hockey&type=_match2&match_id=${this.matchId}&mode=${this.selectedMode}&lang_id=${this.selectedLanguage === 'ru' ? 0 : 1}`,
            filename: this.matchReportFileName.trim() + '.pdf'
          })
          requestBody.reports.push({
            url: `${config.base_url}?API_TYPE=hockey&type=player_report2&match_id=${this.matchId}&mode=${this.selectedMode}&lang_id=${this.selectedLanguage === 'ru' ? 0 : 1}`,
            filename: this.playersReportFileName.trim() + '.pdf'
          })
        } else {
          // Add a single report object based on the selected type
          requestBody.reports.push({
            url: `${config.base_url}?API_TYPE=hockey&type=${this.selectedType}&match_id=${this.matchId}&mode=${this.selectedMode}&lang_id=${this.selectedLanguage === 'ru' ? 0 : 1}`,
            filename: this.fileName.trim() + '.pdf'
          })
        }

        const response = await axios.post('http://reportsutility.pro/api/make-pdf', requestBody)

        console.log('POST request response:', response.data)

        UIkit.notification('PDF generation request sent successfully.', { status: 'success' })
      } catch (error) {
        console.error('Error sending PDF generation request:', error)
        UIkit.notification('An error occurred while sending the PDF generation request.', {
          status: 'danger'
        })
      }
    },
    emailExists (email) {
      return this.emailList.includes(email.trim())
    },
    addEmail () {
      if (isValidEmail(this.newEmail) && !this.emailExists(this.newEmail)) {
        this.emails += (this.emails ? ', ' : '') + this.newEmail.trim()
        this.newEmail = ''
      }
    },
    removeEmail (index) {
      const removedEmail = this.emailList[index].trim()

      // Update this.emails by filtering out the removed email
      this.emails = this.emailList.filter((_, i) => i !== index).join(', ')

      // Update uniqueEmailsList by filtering out the removed email
      this.uniqueEmailsList = this.uniqueEmailsList.filter(email => email.trim() !== removedEmail)
    },
    handlePaste (event) {
      const clipboardData = event.clipboardData || window.clipboardData
      const pastedText = clipboardData.getData('Text')

      const pastedEmails = pastedText.split(/[, ]+/).filter(email => isValidEmail(email))

      // Filter out duplicates from the pasted emails
      const uniqueEmails = Array.from(new Set(pastedEmails))

      // Filter out emails that are already in the uniqueEmailsList
      const newUniqueEmails = uniqueEmails.filter(email => !this.uniqueEmailsList.includes(email))

      if (newUniqueEmails.length === 0) {
        // Display an error message when no new emails are added
        this.emailError = 'All emails are already in the list'
      } else {
        // Add the new unique emails to the input field and uniqueEmailsList
        this.emails += (this.emails ? ', ' : '') + newUniqueEmails.join(', ')
        this.uniqueEmailsList = this.uniqueEmailsList.concat(newUniqueEmails)
      }

      event.preventDefault()
    },
    openEmailListModal () {
      UIkit.modal('#emailListModal').show()
    },
    addEmailList () {
      const pastedEmails = this.emailListText
        .split('\n')
        .map(email => email.trim())
        .filter(email => email !== '')

      // Filter out duplicates from the pasted emails
      const uniqueEmails = Array.from(new Set(pastedEmails))

      // Filter out emails that are already in the uniqueEmailsList
      const newUniqueEmails = uniqueEmails.filter(email => !this.uniqueEmailsList.includes(email))

      if (newUniqueEmails.length === 0) {
        // Display an error message if there are no new unique emails
        UIkit.notification('Emails is already in the list', { status: 'warning' })
      } else {
        // Add the new unique emails to the input field and uniqueEmailsList
        this.emails += (this.emails ? ', ' : '') + newUniqueEmails.join(', ')
        this.uniqueEmailsList = this.uniqueEmailsList.concat(newUniqueEmails)

        // Close the email list modal
        UIkit.modal('#emailListModal').hide()
      }
    },
    changeLanguage () {
      // When the user changes the language, update the "lang" cookie with the selected language value
      this.createCookie('lang', this.selectedLanguage)
    },
    createCookie (name, value) {
      // Helper function to create a cookie
      const date = new Date()
      date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000)) // Cookie expires in 1 year
      const expires = `expires=${date.toUTCString()}`
      document.cookie = `${name}=${value};${expires};path=/`
    },
    getCookie (name) {
      // Helper function to retrieve a cookie by name
      const value = `; ${document.cookie}`
      const parts = value.split(`; ${name}=`)
      if (parts.length === 2) return parts.pop().split(';').shift()
    },
    deleteCookie (name) {
      // Helper function to delete a cookie by setting its expiration date to the past
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
    },
    connectWebSocket () {
      // Connect to the WebSocket server
      this.websocket = new WebSocket('ws://reportsutility.pro/api/status-channel')

      // Listen for WebSocket events
      this.websocket.onopen = () => {
        console.log('WebSocket connection opened')
        this.connected = true
      }

      this.websocket.onmessage = (event) => {
        console.log('WebSocket message received:', event.data)
        this.messages.push(event.data)
      }

      this.websocket.onclose = () => {
        console.log('WebSocket connection closed')
        this.connected = false

        // Attempt to reconnect after a delay
        setTimeout(() => {
          this.connectWebSocket()
        }, this.reconnectInterval)
      }
    }
  }
}

function isValidEmail (email) {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailPattern.test(email)
}
</script>

<style lang="scss" scoped>
/* Custom styles */

#rightDiv {
  max-height: 828px;
  overflow-y: auto;
}

.email-tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 8px;
}

.email-tag {
  display: inline-block;
  margin: 4px;
  padding: 4px 8px;
  background-color: #32d296;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.remove-tag {
  margin-left: 4px;
  cursor: pointer;
}

.uk-icon-close {
  color: red;
}

.message-date {
  font-weight: bold;
  overflow-wrap: break-word;
}

.message-text {
  margin-left: 20px;
  overflow-wrap: break-word;
}

.status-indicator {
  padding: 6px 8px;
  border-radius: 4px;
  display: inline-block;
  line-height: 24px;
  font-weight: bold;
}

.status-connected {
  background-color: #32d296; /* Green for connected */
  color: white;
}

.status-disconnected {
  background-color: #ff4444; /* Red for disconnected */
  color: white;
}

.status-reconnecting {
  background-color: #ffbb33; /* Yellow for reconnecting */
  color: black;
}
</style>
