import { createApp, h } from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'

UIkit.use(Icons)

createApp({
  UIkit,
  render: () => h(App)
}).use(router).mount('#app')

function getCookie (name) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
}

router.beforeEach((to, from, next) => {
  if (to.path === '/sender') {
    // Check if the cookie exists here
    const cookieExists = getCookie('protected_user')

    if (cookieExists) {
      // Allow access to the /sender route
      next()
    } else {
      // Redirect to the login page or any other appropriate route
      next('/')
    }
  } else {
    // Allow access to other routes
    next()
  }
})
