<template>
  <div class="uk-container">
    <div class="uk-flex uk-flex-center uk-flex-middle uk-height-viewport">
      <div class="uk-card uk-card-default uk-card-body uk-width-1-2@s"> <!-- Adjust the width as needed -->
        <h1 class="uk-card-title">Login</h1>
        <form @submit.prevent="login" class="uk-form-stacked">
          <div class="uk-margin">
            <label class="uk-form-label" for="login">Login:</label>
            <div class="uk-form-controls">
              <input
                class="uk-input uk-form-width-large"
                id="login"
                type="text"
                v-model="formData.login"
                required
              />
            </div>
          </div>
          <div class="uk-margin">
            <label class="uk-form-label" for="password">Password:</label>
            <div class="uk-form-controls">
              <input
                class="uk-input uk-form-width-large"
                id="password"
                type="password"
                v-model="formData.password"
                required
              />
            </div>
          </div>
          <div class="uk-margin">
            <button class="uk-button uk-button-primary" type="submit">Log In</button>
          </div>
          <p class="uk-text-danger" v-if="loginError">{{ loginError }}</p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      formData: {
        login: '',
        password: ''
      },
      loginError: null
    }
  },
  methods: {
    async login () {
      this.loginError = null // Clear any previous login error

      try {
        // Send a POST request to /login with the login and password
        const response = await axios.post('http://reportsutility.pro/api/login', this.formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
        // Check the HTTP status code
        if (response.status === 200) {
          // After a successful login, set a cookie
          /*          function setCookie (name, value, days) {
            const date = new Date()
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000)) // Set expiration date
            const expires = `expires=${date.toUTCString()}`
            document.cookie = `${name}=${value}; ${expires}; path=/`
          }

          // Usage after a successful login
          setCookie('protected_user', 'stan', 7) // '7' represents the number of days the cookie will last */

          // If status code is 200, redirect to the main component (SenderComponent)
          this.$router.push('/sender')
        } else {
          // If status code is not 200, show an error or display the login page again
          this.loginError = 'Invalid login or password'
          console.error('Login failed')
          // You can display an error message or reset the form fields
        }
      } catch (error) {
        console.error('Error logging in:', error)
        // Handle the error as needed
      }
    }
  }
}
</script>
